import './simple.css';

const nav = `
<a href="/">Home</a>
`;

const foot = `
<p>Built by Serki with the help of <a href="https://simplecss.org/">simple.css</a>.</p>
<address><a href="mailto:hoi@serki.ch">📧 hoi@serki.ch</a></address>
<p id="food" onmouseover="addFood()"></p>
`
const food = [...
    "🥑🍏🍎🍐🍊🍋🍌🍉🍇🍓🍈🍒🍑🥭🍍🥥🥝🍅🍆🥦🥬🥒🌶🌽🥕🧄🧅🥔🍠🫘🥐🥯🍞🥖🥨🧀🥚🍳🧈🥞🧇🥓🥩🍗🍖🦴🌭🍔🍟🍕🥪🥙🧆🌮🌯🥗🥘🥫🍝🍜🍲🍛🍣🍱🥟🦪🍤🍙🍚🍘🍥🥠🥮🍢🍡🍧🍨🍦🥧🧁🍰🎂🍮🍭🍬🍫🍿🍩🍪🌰🥜🍯🥛🍼☕️🍵🧃🥤🍶🍺🍻🥂🍷🥃🍸🍹🧉🍾🧊🥣🥡🧂"
];

function populateRepetitiveElements() {
    document.getElementById("navi").innerHTML = nav;
    document.getElementById("foot").innerHTML = foot;
}

function insertFood() {
    document.getElementById("food").innerText = "[" + food[0] + "]";
}

function addFood() {
    let nextMeal = food
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
    let ingested = document.getElementById("food").innerText;
    document.getElementById("food").innerText = ingested.substring(0, ingested.length - 1) + "," + nextMeal[0] + "]";
}

function initPage() {
    populateRepetitiveElements();
    insertFood();
}

let noteCollection = new NoteCollection();
let typedKeys = [];
let modal;
let noteBeingEdited;
const KEY_LOCAL_STORAGE_SAFEBOX = "speedyNote-safebox";
const ELEM_ID_NOTES = "notes";
const ELEM_ID_SEARCH = "search";
const ELEM_ID_NEW_NOTE = "newNoteBody";
const ELEM_ID_MODAL = "modal";
const ELEM_ID_MODAL_CLOSE = "modal-close";
const ELEM_ID_EXPORT_BUTTON = "export";
const ELEM_ID_IMPORT_BUTTON = "import";

const CLASS_MODAL = "modal";
const CLASS_MODAL_CONTENT = "modal-content";
const CLASS_CLOSE = "close";
const CLASS_EDIT = "edit";
const CLASS_NOTICE = "notice";
const CLASS_ICON_CONT = "icon-container";
const CLASS_SEARCH = "search";
const CLASS_NAV_CONT = "nav-container";

function NoteCollection(initalNotes) {
    this.notes = !!initalNotes ? initalNotes : [];
}

function Note(id, body) {
    this.id = id;
    this.body = body;
}

function createNewNote(noteText, id) {
    return new Note(!!id ? id : new Date().getTime(), noteText)
}

function addNote(noteText) {
    if (!noteText) return;
    noteCollection.notes.push(createNewNote(noteText));    
    syncNotesWithView();
}

function editNote(noteBeingEdited, noteText) {
    noteBeingEdited.body = noteText;
    syncNotesWithView();
}

function removeNoteClicked(noteId) {
    const clickedNote = noteCollection.notes.filter(note => note.id == noteId)[0];
    const index = noteCollection.notes.indexOf(clickedNote);
    noteCollection.notes.splice(index, 1);
    storeNotes();
    syncNotesWithView();
}

function editNoteClicked(noteId) {
    const clickedNote = noteCollection.notes.filter(note => note.id == noteId)[0];
    showModal(clickedNote);
}

function storeNotes(inputNoteCollection) {
    const notesToStore = !inputNoteCollection ? noteCollection : inputNoteCollection;
    localStorage.setItem(KEY_LOCAL_STORAGE_SAFEBOX, JSON.stringify(notesToStore));
}

function loadNotes() {
    const rawNoteCollection = localStorage.getItem(KEY_LOCAL_STORAGE_SAFEBOX);
    noteCollection = !!rawNoteCollection ? JSON.parse(rawNoteCollection) : new NoteCollection([createNewNote("<h4>Welcome to Speedy Note!</h4>\n\nThe focus of this notebook is to provide a fast way to write down useful pieces of information.\n\n<p>You can format the notes with HTML, but don't have to. Have a look by clicking the edit button <b>E</b> on the top right.</p>\n\nYou can add in-line code like <code>.oO0Oo.</code> or add a code block:\n\n<pre>\nif (fun > 10) return \"Yay!\";\nelse return \"Nay\";\n</pre>\n\n<p>Search by just typing the term you are looking for. Use <kbd>Esc</kbd> to clear the search.</p>\n\n<p>Finally, you can delete this example note by clicking the <b>X</b> on the top right.</p>", 1)]);    
}

function filterNotesBy(term) {
    if (!term) return noteCollection.notes;
    return noteCollection.notes.filter(note => note.body.toLowerCase().includes(term.toLowerCase()));
}

function syncNotesWithView() {
    const notesToSync = filterNotesBy(typedKeys.join(""));
    const notesElem = document.getElementById(ELEM_ID_NOTES);
    notesElem.innerHTML = '';
    notesToSync.forEach((note) => { notesElem.appendChild(convertNoteToViewNote(note)) });
}

function convertNoteToViewNote(note) {
    let containerElem = document.createElement('div');
    containerElem.setAttribute("class", CLASS_NOTICE);

    const iconContainer = document.createElement('div');
    iconContainer.setAttribute("class", CLASS_ICON_CONT);
    containerElem.appendChild(iconContainer);

    let closeElem = document.createElement('span');
    closeElem.setAttribute("class", CLASS_CLOSE);
    closeElem.setAttribute("id", `close-${note.id}`);
    closeElem.innerHTML = "X";
    closeElem.onclick = (event) => {
        const clickedId = event.currentTarget.id.split("-")[1]
        removeNoteClicked(clickedId);
    };
    iconContainer.appendChild(closeElem);

    let editElem = document.createElement('span');
    editElem.setAttribute("class", CLASS_EDIT);
    editElem.setAttribute("id", `edit-${note.id}`);
    editElem.innerHTML = "E";
    editElem.onclick = (event) => {
        const clickedId = event.currentTarget.id.split("-")[1]
        editNoteClicked(clickedId);
    };
    iconContainer.appendChild(editElem);

    let bodyElem = document.createElement('div');
    bodyElem.innerHTML = note.body;

    containerElem.appendChild(iconContainer);
    containerElem.appendChild(bodyElem);

    return containerElem;
}

function resetSearch() {
    typedKeys = [];
    syncSearchView();
}

function addToSearch(c) {
    typedKeys.push(c);
    syncSearchView();
}

function removeOneFromSearch() {
    typedKeys.pop();
    syncSearchView();
}

function syncSearchView() {
    const searchBox = document.getElementById(ELEM_ID_SEARCH);
    if (searchBox !== document.activeElement){
        searchBox.value = typedKeys.join("");
    }
}

document.addEventListener('keydown', function(event) {    
    if (isModalActive()) {
        if (event.ctrlKey && event.key === 'Enter') {
            processNewNote();
            return;
        }

        if (event.key === 'Escape') {
            hideModal();            
            return;
        }
    } else {
        if (event.ctrlKey && event.key === 'c' || event.metaKey && event.key === 'c') {
            return true;
        }

        if (event.ctrlKey && event.key === 'v' || event.metaKey && event.key === 'v') {
            // show modal and paste from clipboard by letting the event through
            showModal();
            return true;
        }

        if (event.ctrlKey && event.key === 'y') {
            showModal();
            return;
        }

        if (event.key === 'Escape') {
            resetSearch();
            syncNotesWithView();
            return;
        }

        if (event.key.length === 1) {
            addToSearch(event.key);
            syncNotesWithView();
            return;
        }

        if (event.key === 'Backspace') {
            removeOneFromSearch();
            syncNotesWithView();
            return;
        }
    }
});

function showModal(note) {
    modal.style.display = "block";
    const textArea = getNoteCompositionElem();
    textArea.value = note ? note.body : '';
    textArea.focus();
    noteBeingEdited = note;
}

function processNewNote() {
    const noteElem = getNoteCompositionElem();

    if (noteBeingEdited) {
        editNote(noteBeingEdited, noteElem.value);
    } else {
        addNote(noteElem.value);
    }

    storeNotes();
    noteElem.value = null;
    hideModal();
}

function getNoteCompositionElem() {
    return document.getElementById(ELEM_ID_NEW_NOTE);
}

function isModalActive() {
    return modal.style.display === "block";
}

function hideModal() {
    modal.style.display = "none";
}

window.onclick = (event) => {
    if (event.target === modal) {
        processNewNote();
    }
}

window.onload = () => {
    loadNotes();
    syncNotesWithView();
    modal = document.getElementById(ELEM_ID_MODAL);
    document.getElementById(ELEM_ID_MODAL_CLOSE).onclick = processNewNote;
}

function createSearchBox() {
    const element = document.createElement('input');
    element.setAttribute("id", ELEM_ID_SEARCH);
    element.setAttribute("placeholder", "Search note...");
    element.setAttribute("type", "search");
    element.setAttribute("class", CLASS_SEARCH);
    return element;
}

function createNotes() {
    const element = document.createElement('div');
    element.setAttribute("id", ELEM_ID_NOTES);
    return element;
}

function createModal() {
    const element = document.createElement('div');
    element.setAttribute("id", ELEM_ID_MODAL);
    element.setAttribute("class", CLASS_MODAL);
    element.appendChild(createModalContent());
    return element;
}

function createModalContent() {
    const element = document.createElement('div');
    element.setAttribute("class", CLASS_MODAL_CONTENT);

    const elementSpan = document.createElement('span');
    elementSpan.setAttribute("id", ELEM_ID_MODAL_CLOSE);
    elementSpan.setAttribute("class", CLASS_CLOSE);
    elementSpan.innerHTML = "X";
    element.appendChild(elementSpan);

    const elementLabel = document.createElement('label');
    elementLabel.setAttribute("for", ELEM_ID_NEW_NOTE);
    elementLabel.innerText = "Note:";
    element.appendChild(elementLabel);

    const elementTextArea = document.createElement('textarea');
    elementTextArea.setAttribute("id", ELEM_ID_NEW_NOTE);
    elementTextArea.setAttribute("name", ELEM_ID_NEW_NOTE);
    elementTextArea.setAttribute("rows", 15);
    elementTextArea.setAttribute("cols", 30);
    elementTextArea.setAttribute("placeholder", "Add your note here");
    elementTextArea.setAttribute("autofocus", "true");
    element.appendChild(elementTextArea);

    return element;
}

function createLeftBar() {
    return wrapIns('div', [
        createTipsSection(),
        withInnerText('h4', 'Tools'),
        wrapIn('div', createExportButton()),
        wrapIn('div', createImportButton())
    ]);
}

function createTipsSection() {
    return wrapIns('div', [
        withInnerText('h4', 'Shortcuts'),
        createTip("Ctrl + y", "Create new note"),
        createTip("Ctrl + Enter", "Save note"),
        createTip("Esc", "Clear search OR close modal without saving note"),
        withInnerText('h4', 'Hints'),
        withInnerText('p', 'Start typing to search notes'),
        withInnerText('p', 'Notes are stored locally in your browser, nothing is transmitted anywhere. If you clear the browser storage, they are gone. Consider exporting them once in a while'),
        withInnerHtml('p', 'You can insert HTML tags to format your notes, For example: &lt;b&gt;<b>bold</b>&lt;/b&gt;'),
        withInnerHtml('p', 'Insert in-line code: <code>&lt;code&gt;sampleCode&lt;/code&gt;</code>'),
        withInnerHtml('p', 'Insert a code block: <pre>&lt;pre&gt;sampleCode&lt;/pre&gt;</pre>'),
    ]);    
}

function createTip(shortcut, description) {
    return wrapIns('p', [
        createKbd(shortcut),
        createSpan(' --> '),
        createSpan(description)
    ]);    
}

function createKbd(shortcut) {
    return withInnerText('kbd', shortcut);    
}

function createSpan(text) {
    return withInnerText('span', text);    
}

function wrapIn(wrapper, element) {   
    return wrapIns(wrapper, [element]);
}

function wrapIns(wrapper, elements) {   
    const elem = document.createElement(wrapper);
    elements.forEach(element => elem.appendChild(element));
    return elem;    
}

function withInnerText(elem, text) {
    const element = document.createElement(elem);
    element.innerText = text;        
    return element;
}

function withText(elem, text) {
    const element = document.createElement(elem);
    element.text = text;        
    return element;
}

function withInnerHtml(elem, html) {
    const element = document.createElement(elem);
    element.innerHTML = html;    
    return element;
}

function elemWithAttrs(elem, attrs) { 
    const element = document.createElement(elem);    
    Object.keys(attrs).forEach(key => element.setAttribute(key, attrs[key]));
    return element;
}

function createExportButton() {
    const exportElem = document.createElement('button');
    exportElem.setAttribute("id", ELEM_ID_EXPORT_BUTTON);
    exportElem.innerHTML = "Export";
    exportElem.onclick = function() {
        const blob = new Blob([JSON.stringify(noteCollection)], { type: "application/json" });

        // Create a temporary <a> element to trigger the download
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "speedyNote.json";

        // Trigger a click event to download the file
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();

        // Clean up
        document.body.removeChild(a);
    };
    return exportElem;
}

function createImportButton() {
    const importElem = document.createElement('button');
    importElem.setAttribute("id", ELEM_ID_IMPORT_BUTTON);
    importElem.innerHTML = "Import";
    importElem.onclick = function() {
        // Create an input element of type "file"
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = "application/json"; // Allow only JSON files

        // Trigger a click event on the input to open the file dialog
        fileInput.click();

        // Add an event listener to handle the selected file
        fileInput.addEventListener("change", function() {
            const selectedFile = fileInput.files[0];

            // Check if the selected file is a JSON file
            if (selectedFile.type === "application/json") {
                const reader = new FileReader();

                reader.onload = function(event) {
                    const jsonContent = event.target.result;

                    // Parse the JSON content
                    try {
                        const jsonData = JSON.parse(jsonContent);
                        storeNotes(jsonData);
                        loadNotes();
                        syncNotesWithView();
                    } catch (error) {
                        console.error("Error parsing JSON:", error);
                    }
                };

                // Read the selected JSON file as text
                reader.readAsText(selectedFile);
            } else {
                alert("Please select a valid JSON file.");
            }
        });
    };
    return importElem;
}

document.body.appendChild(createLeftBar());
document.body.appendChild(wrapIns('main', [
    createSearchBox(),
    createNotes(),
    createModal()
]));
document.head.appendChild(elemWithAttrs('script', {async: '', src: 'https://www.googletagmanager.com/gtag/js?id=G-CP8N0YYT4D'}));
document.head.appendChild(withText('script', `
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-CP8N0YYT4D');
`));
document.documentElement.lang = "en";
